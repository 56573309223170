const en = {
    "WINS": "WINS",
    "DETAILS": "DETAILS",
    "LOSES": "LOSSES",
    "LOSESSHORT": "LOSSES",
    "WINSSHORT": "WINS",
    "STARS": "4-5⭐",
    "STARS2": "1-3⭐",
    "AVERAGE": "Average",
    "TEAM": "TEAM RATING",

    "TIME_REMAINING": "Time Remaining",
    "QUINCE_CENTRAL": "QUINCE CENTRAL",

    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",

    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Apr",
    "May": "May",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Aug",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dec",

    "Select_lan": "Languaje",
    "TODAY": "Today",

    "MANAGER": "MANAGER",
    "MANAGERS": "MANAGERS",

    "SUMMARY": "SURVEY SUCCESS",
    "HOSTESS": "HOSTESS",
    "HOSTS": "HOSTS",
    "SERVICE": "SERVICE",
    "BAR": "BAR",
    "BAR/DRINKS": "BAR / DRINKS",
    "KITCHEN": "KITCHEN",
    "KITCHEN/FOOD": "KITCHEN / FOOD",
    "CASHIERS": "CASHIERS",

    "MUSIC": "MUSIC",

    "OVERALL": "OVERALL",
    "PERFECTION": 'ASKED IF \n"PERFECT"',
    "TAGS": "TAGS",
    "LOBBY": "LOBBY",
    "DRINK": "DRINK",
    "CHECK": "CHECK",
    "OPERATOR": "ASSISTANT MANAGER",

    "PERFECT": "PERFECT",
    "Yes Manager": "Yes Manager",
    "Yes Server": "Yes Server",
    "Other": "Other",
    "Don't Remember": "Don't Remember",
    "No one": "No one",

    "SIDE_BAR": "TODAY/(WEEK)",

    "TABLE": "Table",
    "TOUCH_TABLE": "Touch Table",
    "TOUCH_TABLE_CAPS": "TOUCHED TABLE",

    "TOTAL": "TOTAL",
    "OPEN": "OPEN",
    "CLOSE": "CLOSE",

    "TICKETS": "TICKETS",
    "ACTIVATIONSPERCENT": "% ACTIVATIONS",
    "COMPLETEDPERCENT": "COMPLETED % VS ACTIVATIONS",
    "REJECTEDPERCENT": "REJECTED % VS ACTIVATIONS",
    "ACTIVETICKETS": "ACTIVE / TICKETS",

    "TICKETSABBR": "TKTS",
    "ACTIVATIONSPERCENTABBR": "%ACTIVE",
    "COMPLETEDPERCENTABBR": "%COMP/  ACTIVE",
    "REJECTEDPERCENTABBR": "%REJ / ACTIVE",
    "ACTIVETICKETSABBR": "ACTIVE / TICKETS",

    "ACTIVATIONS": "ACTIVE",
    "COMPLETED": "COMPLETED",
    "REJECTED": "REJECTED",

    "CROSSMARK": "❌",
    "CHECKMARK": "✅",
    "ONLY_STARS": "1⭐",

    "ACTIVATIONSABBR": "ACTIVE",
    "COMPLETEDABBR": "COMPLETE",
    "REJECTEDABBR": "REJECTED",

    "ANY_RATINGS": "ANY RATINGS",
    "CHECK_TIME_AVG": "CHECK TIME AVERAGE",
    "MINUTES": "minutes",
    "WITH_RATINGS": "WITH RATINGS",

    "Attitude": "Attitude",
    "Always Smiling" : "Always Smiling",
    "Smiling Enough": "Smiling Enough",
    "Smiling enough": "Ok",
    "Frowning": "Frowning",

    "Demeanor": "Demeanor",
    "Very Friendly" : "Very friendly",
    "Semi Friendly" : "Semi-friendly",
    "Unfriendly": "Unfriendly",

    "Pace": "Pace",
    "Moved fast" : "Moved fast",
    "Ok speed" : "Ok speed",
    "Snail pace" : "Slow",

    "Interactions": "Interactions",
    "Comfortable": "Comfortable interactions",
    "Too Many": "Too many interruptions",
    "Too many": "Too many",

    "Knows menu": "Knows Menu",
    "Perfect": "Perfect",
    "Ok": "Ok",
    "Knows menu Ok": "Ok",
    "Not good": "Not good",

    "Food Delivery": "Food delivery",
    "Food Delivery Perfect": "Perfect",
    "Adequate": "Adequate",
    "Slow": "Slow",
    "No opinion": "No opinion",

    "Food Temperature": "Food temp.",
    "Food Temperature Perfect": "Perfect",
    "Food Temperature Adequate": "Adequate",
    "Incorrect": "Incorrect",

    "Very Helpful": "Very Helpful",
    "Helpful": "Helpful",
    "Not helpful": "Not helpful",
    "Not Helpful": "Not Helpful",
    "No Help Needed": "No Help Needed",
    "Not helpful when needed": "Not helpful when needed",

    "Reservations": "Booked table",
    "Very professional": "Very Profess.",
    "Not professional": "Not Profess.",
    "I booked online": "Online",
    "Not booked": "Not booked",

    "Fast & Flowing": "Fast & Flowing",
    "Fast Flowing": "Fast & Flowing",
    "Friendly": "Friendly",
    "Face": "Face",
    "Smiling": "Smiling",
    "Great": "Great", 
    "No problem": "No problem",
    "Not Smiling": "Not Smiling",
    "Poor": "Poor",
    "Always Delayed": "Always Delayed",
    "unknown": "unknown",
    "Plesant": "Plesant",
    "OK Comm": "OK Comm.",
    "Not friendly": "Not friendly",
    "Super Friendly": "Super Friendly",
    "Fast": "Fast",
    "No Opinion": "No Opinion",
    "Communication": "Communication",
    "Perfectly": "Perfectly",
    "No_Opinion": "No Opinion",

    "East": "East",
    "West": "West",
    "North": "North",
    "South": "South",

    "Interruptions": "Interruptions",
    "Temperature": "Temperature",
    "Drink": "Drink service",
    "Iteractions": "Iteractions",
    "Follow Up": "Follow up",
    "Met Chef": "Met Chef",
    "No Met Chef": "No Met Chef",

    "other": "Other",

    "wrongGenre": "Wrong Genre",
    "tooLoud": "Too Loud",
    "notFamiliar": "Not Familiar",

    "Cancel": 'Cancel',

    "SHIFTS": "SHIFTS",
    "DAYS": "DAYS",
    "MONTHS": "MONTHS",
    "YEARS": "YEARS",

    'Open-1pm': 'Open-1pm',
    '1pm-5pm': '1pm-5pm',
    '5pm-7pm': '5pm-7pm',
    '7pm-11PM': '7pm-11PM',
    '11pm-Close': '11pm-Close',

    "activations": "ACTIVATIONS",
    "withData": "COMPLETED WITH DATA %",
    "withStar": "COMPLETED WITH GENERAL STAR %",

    "Select": "Select",
    "Daily": "Daily",
    "Weekly": "Weekly",
    "Monthly": "Monthly",
    "Yearly": "Yearly",

    "0-5 min": "Acceptable timing",
    "5-10 min": "Small delay",
    "10-20 min": "Long delay (asked 1x)",
    "20-30 min": "Long delay (asked more than 2x)",
    "30-45 min": "Long delay (asked more than 2x)",
    "45-60 min": "Long delay (asked more than 2x)",
    "+60 min": "Long delay (asked more than 2x)",

    "CHECK_TIME_NOT_ACCEPTABLE": "NOT ACCEPTABLE",

    "General Information": "General Information",
    "Take Action": "Take Action",
    "Survey ID": "Survey ID",
    "Language": "Language",
    "Table": "Table",
    "Time Sent": "Time Sent",
    "Visiting Time": "Visiting Time",
    "Waiter": "Waiter",
    "Total Bill": "Total Bill",
    "Customer Solution": "Customer Solution",
    "Add Comment": "Add Comment",
    "Solution": "Solution",
    "Courtesy Type": "Courtesy Type",
    "Courtesy": "Courtesy",
    "Not necessary": 'Not necessary',
    "No action": "No action",
    "Free drinks": "Free drinks",
    "Free desserts": "Free desserts",
    "Discount on bill": "Discount on bill",
    'Future credit': "Future credit",
    "Table Comment": "Table Comment",
    "Continue": "Continue",
    "Do you want to report a problem with a table?": "Do you want to report a problem with a table?",
    "Insert the table number": "Insert the table number",
    "Table Number": "Table Number",
    "Date": "Date",
    "Activation Time": "Activation Time",
    "View Survey Rating": "View Survey Rating",
    "View Survey Details": "View Survey Details",
    "General Manager": "General Manager",
    "Server": "Server",
    "Host": "Host",
    "Kitchen/Food": "Kitchen/Food",
    "Bar/Drinks": "Bar/Drinks",
    "Tags received": "Tags received",
    "Average": "Average",
    "Overall": "Overall",
    "Via Tags": "Via Tags",
    "Survey Rating": "Survey Rating",
    "Items": "Items",
    "View Details": "View",
    "Ticket ID": "Ticket ID",
    'Pick one...': 'Pick one...',
    'Write your comment...': "Write your comment...",
    "server error": "server error",
    "Tickets no encontrados para la mesa seleccionada": "Tickets not found for the selected table"
}


export default en;