const es = {
    "WINS": "GANADOS",
    "DETAILS": "DETALLES",
    "LOSES": "PERDIDOS",
    "LOSESSHORT": "PIERDE",
    "WINSSHORT": "GANA",
    "STARS": "4-5⭐",
    "STARS2": "1-3⭐",
    "AVERAGE": "Promedio",
    "TEAM": "CALIFICACIÓN EQUIPO",

    "TIME_REMAINING": "Tiempo Restante",
    "QUINCE_CENTRAL": "QUINCE CENTRAL",

    "Monday": "Lunes",
    "Tuesday": "Martes",
    "Wednesday": "Miércoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sábado",
    "Sunday": "Domingo",

    "Jan": "Ene",
    "Feb": "Feb",
    "Mar": "Mar",
    "Apr": "Abr",
    "May": "May",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Ago",
    "Sep": "Sep",
    "Oct": "Oct",
    "Nov": "Nov",
    "Dec": "Dic",

    "Select_lan": "Idioma",
    "TODAY": "Hoy",

    "MANAGER": "GERENTE",
    "MANAGERS": "GERENTES",

    "SUMMARY": "RESULTADOS",
    "HOSTESS": "HOSTESS",
    "HOSTS": "HOSTS",
    "SERVICE": "MESEROS",
    "BAR": "BARRA",
    "BAR/DRINKS": "BARRA / BEBIDAS",
    "KITCHEN": "COCINA",
    "KITCHEN/FOOD": "COCINA / COMIDA",
    "CASHIERS": "CAJA",

    "MUSIC": "MÚSICA",

    "OVERALL": "GENERAL",
    "PERFECTION": 'PREGUNTÓ SI "PERFECTO"',
    "TAGS": "ETIQUETAS",
    "LOBBY": "LOBBY",
    "DRINK": "BEBIDAS",
    "CHECK": "CUENTA",
    "OPERATOR": "ASISTENTE DE GERENTE",

    "PERFECT": "PERFECCIÓN",
    "Yes Manager": "Si, Gerente",
    "Yes Server": "Si, mesero",
    "Other": "Otro",
    "Don't Remember": "No recuerdo",
    "No one": "Nadie",

    "SIDE_BAR": "HOY/(SEMANA)",

    "TABLE": "Mesa",
    "TOUCH_TABLE": "Visitó mesa",
    "TOUCH_TABLE_CAPS": "VISITÓ MESA",

    "TOTAL": "TOTAL",
    "OPEN": "ABRE",
    "CLOSE": "CIERRA",

    "TICKETS": "TICKETS",
    "ACTIVATIONSPERCENT": "% ACTIVACIONES",
    "COMPLETEDPERCENT": "COMPLETADAS % VS ACTIVACIONES",
    "REJECTEDPERCENT": "RECHAZADAS % VS ACTIVACIONES",
    "ACTIVETICKETS": "ACTIVAS / TICKETS",

    "TICKETSABBR": "TKTS",
    "ACTIVATIONSPERCENTABBR": "% ACTIVAS",
    "COMPLETEDPERCENTABBR": "%COMP / ACTIVAS",
    "REJECTEDPERCENTABBR": "%RCHZDS / ACTIVAS",
    "ACTIVETICKETSABBR": "ACTIVAS / TICKETS",

    "ACTIVATIONS": "Activaciones",
    "COMPLETED": "Completadas",
    "REJECTED": "Rechazadas",

    "CROSSMARK": "❌",
    "CHECKMARK": "✅",
    "ONLY_STARS": "1⭐",

    "ACTIVATIONSABBR": "Activas",
    "COMPLETEDABBR": "Comp",
    "REJECTEDABBR": "Rchz",

    "ANY_RATINGS": "ANY RATINGS",
    "CHECK_TIME_AVG": "TIEMPO DE CUENTA PROMEDIO",
    "MINUTES": "minutos",
    "WITH_RATINGS": "CON CALIF",

    "Attitude": "Actitud",
    "Always Smiling" : "Siempre sonriente",
    "Smiling Enough": "Sonrie Suficiente",
    "Smiling enough": "Ok",
    "Frowning": "Disgustado",

    "Demeanor": "Comportamiento",
    "Very Friendly" : "Super Amable",
    "Semi Friendly" : "Amable",
    "Unfriendly": "Hostil",

    "Pace": "Velocidad",
    "Moved fast" : "Rápido",
    "Ok speed" : "Buena Velocidad",
    "Snail pace" : "Lento",

    "Interactions": "Interacciones",
    "Comfortable": "Adecuadas",
    "Too Many": "Demasiadas Interrupciones",
    "Too many": "Demasiadas",

    "Knows menu": "Conoce el menú",
    "Perfect": "Perfecto",
    "Ok": "Ok",
    "Knows menu Ok": "Bien",
    "Not good": "No es bueno",

    "Food Delivery": "Entrega platillos",
    "Food Perfect": "Perfecta",
    "Adequate": "Adecuada",
    "Slow": "Lenta",
    "No opinion": "Sin opinión",

    "Food Temperature": "Temp. comida",
    "Food Temperature Perfect": "Perfecta",
    "Food Temperature Adequate": "Adecuada",
    "Incorrect": "Incorrecta",

    "Very Helpful": "Muy Servicial",
    "Helpful": "Servicial",
    "Not helpful": "De poca ayuda",
    "Not Helpful": "De poca ayuda",
    "No Help Needed": "No necesitó ayuda",
    "Not helpful when needed": "No Servicial al Necesitarle",

    "Reservations": "Reservación de mesa",
    "Very professional": "Muy Profes.",
    "Not professional": "No Profes.",
    "I booked online": "En línea",
    "Not booked": "No reservó",

    "Fast & Flowing": "Rápido y Fluido",
    "Fast Flowing": "Rápido y Fluido",
    "Friendly": "Amigable",
    "Face": "Expresión",
    "Smiling": "Sonriente",
    "Great": "Genial",
    "No problem": "Sin problema",
    "Not Smiling": "No sonrie",
    "Poor": "Pobre",
    "Always Delayed": "Siempre retrasado",
    "unknown": "Desconocido",
    "Plesant": "Agradable",
    "OK Comm": "Com. OK",
    "Not friendly": "No amigable",
    "Super Friendly": "Super Amigable",
    "Fast": "Rápido",
    "No Opinion": "Sin opinión",
    "Communication": "Comunicación",
    "Perfectly": "Perfecto",
    "No_Opinion": "Sin opinión",

    "East": "Este",
    "West": "Oeste",
    "North": "Norte",
    "South": "Sur",

    "Interruptions": "Interruptions",
    "Temperature": "Temp. Comida",
    "Drink": "Serv. Bebida",
    "Iteractions": "Interacción",
    "Follow Up": "Seguimiento",
    "Met Chef": "Visita Chef",
    "No Met Chef": "No Visita Chef",

    "other": "Otro",

    "wrongGenre": "Género incorrecto",
    "tooLoud": "Muy ruidoso",
    "notFamiliar": "No Familiar",

    "Cancel": 'Cancelar',

    "SHIFTS": "Turnos",
    "DAYS": "DIAS",
    "MONTHS": "MESES",
    "YEARS": "AÑOS",

    'Open-1pm': 'Abierto-1pm',
    '1pm-5pm': '1pm-5pm',
    '5pm-7pm': '5pm-7pm',
    '7pm-11PM': '7pm-11PM',
    '11pm-Close': '11pm-Cierre',

    "activations": "ACTIVACIONES",
    "withData": "COMPLETO CON % DE DATOS",
    "withStar": "COMPLETO CON % GENERAL DE ESTRELLAS",

    "Select": "Seleccione",
    "Daily": "Diario",
    "Weekly": "Semanal",
    "Monthly": "Mensual",
    "Yearly": "Anual",

    "0-5 min": "Tiempo aceptable",
    "5-10 min": "Tardó",
    "10-20 min": "Tardó mucho (preguntó 1x)",
    "20-30 min": "Preguntó muchas veces",
    "30-45 min": "Preguntó muchas veces",
    "45-60 min": "Preguntó muchas veces",
    "+60 min": "Preguntó muchas veces",

    "CHECK_TIME_NOT_ACCEPTABLE": "INACEPTABLE",

    "General Information": "Información General",
    "Take Action": "Tomar Acción",
    "Survey ID": "ID de la encuesta",
    "Language": "Idioma",
    "Table": "Mesa",
    "Time Sent": "Hora Enviada",
    "Visiting Time": "Tiempo de visita",
    "Waiter": "Mesero",
    "Total Bill": "Total de la cuenta",
    "Customer Solution": "Solución del cliente",
    "Add Comment": "Agregar comentario",
    "Solution": "Solución",
    "Courtesy Type": "Tipo de cortesía",
    "Courtesy": "Cortesía",
    "Not necessary": "No es necesario",
    "No action": "Sin acción",
    "Free drinks": "Bebidas gratis",
    "Free desserts": "Postres gratis",
    "Discount on bill": "Descuento en la cuenta",
    "Future credit": "Crédito futuro",
    "Table Comment": "Comentario de la mesa",
    "Continue": "Continuar",
    "Do you want to report a problem with a table?": "Quieres reportar un problema con una mesa?",
    "Insert the table number": "Ingresa el número de la mesa",
    "Table Number": "Número de la mesa",
    "Date": "Fecha",
    "Activation Time": "Hora de activación",
    "View Survey Rating": "Ver la puntuación de la encuesta",
    "View Survey Details": "Ver los detalles de la encuesta",
    "General Manager": "Gerente General",
    "Server": "Servicio",
    "Host": "Anfitrión",
    "Kitchen/Food": "Cocina/Alimentos",
    "Bar/Drinks": "Barra/Bebidas",
    "Tags received": "Etiquetas recibidas",
    "Average": "Promedio",
    "Overall": "Total",
    "Via Tags": "Por Etiquetas",
    "Survey Rating": "Puntuación de la Encuesta",
    "Items": "Elementos",
    "View Details": "Ver",
    "Ticket ID": "Ticket ID",
    'Pick one...': 'Escoge una opción...',
    "Write your comment...": "Escribe tu comentario...",
    "server error": "error en el servidor",
    "Tickets no encontrados para la mesa seleccionada": "Tickets no encontrados para la mesa seleccionada"
}


export default es;